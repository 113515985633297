.rmlbqrf{max-width:450px;-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.5rem;-webkit-box-pack:stretch;-ms-flex-pack:stretch;-webkit-justify-content:stretch;justify-content:stretch;}
.s1k6kyc5{-webkit-flex:1;-ms-flex:1;flex:1;}
.ic0aans.ic0aans{background:#F1F1F2;border-radius:26px;box-shadow:none;}.ic0aans.ic0aans:placeholder-shown{text-overflow:ellipsis;}
.i1hyxyj7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.5rem;}
.ibvdh46{color:#757577;}
.alidq7i.alidq7i.alidq7i{box-shadow:0px 0px 14.1px -2px rgba(195, 203, 217, 0.4);border-width:0;background:linear-gradient(180deg, #8598cd 0%, #43CCF8 100%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent;}.alidq7i.alidq7i.alidq7i:hover{background:#43CCF8;color:white;-webkit-background-clip:initial;background-clip:initial;-webkit-text-fill-color:initial;}

.r1x72ds{max-height:80vh;overflow-y:auto;min-width:100%;max-width:750px;width:calc(100vw - 2rem);padding:0.5em;}@media screen and (width > 640px){.r1x72ds{padding:2em;max-height:40vh;}}.r1x72ds.fy69uxb{width:80vw;max-height:90vh;}@media screen and (width > 1200px){.r1x72ds.fy69uxb{width:70vw;max-width:900px;}}
.l1vfzhfz{width:2rem;height:2rem;display:blockquote;margin:0.75rem auto;}
.srlg0iw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:stretch;-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;gap:0.5rem;padding:0.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-weight:700;font-size:18px;}.srlg0iw:not(:first-child){margin-top:1rem;}
.i1dthazk{font-size:16px;font-weight:700;}
.iqekt1b{font-size:14;font-weight:300;color:#C3CBD9;}
